<template>
    <div class="ele-body">
      <el-card shadow="never">
        <!-- 搜索表单 -->
        <el-form :model="table.where" class="ele-form-search"
            @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
            <div class="d-flex">
                <el-form-item label="订单号:" label-width="80px">
                    <el-input v-model="table.where.order_id" placeholder="请输入订单号" style="width:180px;margin-right: 20px;" clearable/>
                </el-form-item>
                <el-form-item label="用户手机号:" label-width="100px">
                    <el-input v-model="table.where.user_phone" placeholder="请输入手机号" style="width:180px;margin-right: 20px;" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">查询</el-button>
                    <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
                </el-form-item>
            </div>
        </el-form>
        <!-- 数据表格 -->
        <ele-data-table ref="table" :config="table" height="calc(100vh - 260px)" highlight-current-row>
          <template slot-scope="{index}">
            <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip/>
            <el-table-column prop="order_id" label="订单号" align="center" show-overflow-tooltip min-width="150"/>
            <el-table-column prop="user_phone" label="用户手机号" align="center" show-overflow-tooltip min-width="150"/>
            <el-table-column prop="driver_phone" label="司机手机号" align="center" show-overflow-tooltip min-width="150"/>
            <el-table-column prop="old_address" label="修改前的省/市/区" align="center" show-overflow-tooltip min-width="130"/>
            <el-table-column prop="address" label="修改后的省/市/区" align="center" show-overflow-tooltip min-width="130"/>
            <el-table-column prop="create_time" label="创建时间" align="center" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="update_time" label="更新时间" align="center" min-width="160" show-overflow-tooltip></el-table-column>
          </template>
        </ele-data-table>
      </el-card>
    </div>
  </template>
  
  <script>
  export default {
    name: "SysUser",
    data() {
      return {
        table: {url: '/order/orderUpdateRecord', where: {}},  // 表格配置
      }
    },
    methods: {
      /* 刷新 */
      reload() {
        //console.log('lll');
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>